import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ApiLocalAuthorityGroup } from 'src/app/local-authority';
import { RouteMonitorService } from 'src/app/routing';
import { ButtonDefinition } from 'src/app/toolbar';

/**
 * A component that allows selection of areas and sets a
 * query string parameter to the selected values.  Optionally
 * the values can be slaved to a second query paramter controlled
 * by another instance of this component.  While slaving is active
 * wht slave instance of the component is disabled.
 */
@Component({
  selector: 'fw-area-selector',
  templateUrl: './area-selector.component.html',
  styleUrls: ['./area-selector.component.scss']
})
export class AreaSelectorComponent implements OnDestroy {

  /**
   * A stream that emits when the component is destroyed.
   */
  private readonly destroyed$ = new Subject<void>();

  /**
   * A stream of changes to the input observable containing the available areas.
   */
  private readonly areaChanged$ = new ReplaySubject<Observable<{ id: string; name: string }[]>>(1);

  /**
   * A stream to trigger toggling of whether slaving is active.
   */
  private readonly slavingModeToggleTrigger$ = new ReplaySubject<void>(1);

  /**
   * A stream to trigger a change of managed parameter name.
   */
  private readonly parameterNameChangeTrigger$ = new ReplaySubject<string>(1);

  /**
   * A stream to trigger a change of slave parameter name.
   */
  private readonly slaveParameterNameChangeTrigger$ = new ReplaySubject<string>(1);

  /**
   * A stream to trigger processing of changes to the areas which are selected.
   */
  private readonly selectedAreaChangedTrigger$ = new ReplaySubject<string[]>(1);

  /**
   * A stream of booleans to determine whether slaving mode is active.
   */
  private readonly isSlaving$ = this.routeMonitorService.getParameter$('isSlaving').pipe(
    distinctUntilChanged(),
    map(x => x === 'true'),
    startWith(false),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of the name of the parameter we are managing.
   */
  private readonly parameterName$ = this.parameterNameChangeTrigger$.pipe(
    startWith(null as string),
    distinctUntilChanged(),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of the name of the parameter we are slaving to.
   */
  private readonly slaveParameterName$ = this.slaveParameterNameChangeTrigger$.pipe(
    startWith(null as string),
    distinctUntilChanged(),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream of the object to control the suffix buttons on the control.
   */
  public readonly areaSuffixButtons$ = combineLatest([
    this.isSlaving$,
    this.slaveParameterName$
  ]).pipe(
    map(([isSlaving, parameter]) => parameter ? [
      {
        icon: isSlaving ? 'icon-multi-doc-locked' : 'icon-multi-doc-standard',
        data: 'ToggleSlaving'
      }
    ] : null)
  );

  /**
   * A stream of the current values from the parameter we are controlling.
   */
  private readonly parameterValues$ = this.parameterName$.pipe(
    switchMap(x => this.routeMonitorService.getParameterArray$(x))
  );

  /**
   * Sets the stream of areas.
   */
  @Input() public set areas$(value: Observable<{ id: string; name: string }[]>) {
    this.areaChanged$.next(value);
  }

  /**
   * Gets the stream of areas.
   */
  public get areas$(): Observable<{ id: string; name: string }[]> {
    return this.areasStream$;
  }

  /**
   * Sets the name of the parameter in the route to set with the selected values.
   */
  @Input() public set parameter(value: string) {
    this.parameterNameChangeTrigger$.next(value ? value : '');
  }

  /**
   * The name of the parameter in the route to slave the selected values to.
   */
  @Input() public set slaveParameter(value: string) {
    this.slaveParameterNameChangeTrigger$.next(value);
  }

  /**
   * A stream of the currently available Areas.
   */
  private readonly areasStream$ = this.areaChanged$.pipe(
    filter(x => x !== null && x !== undefined),
    switchMap(x => x),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Prefix buttons of local authority area selector.
   */
  public readonly areaPrefixButtons: ButtonDefinition[] = [
    {
      icon: 'icon-action-filtering'
    }
  ];

  /**
   * A {@link FormControl} to hold the currently selected areas.
   */
  private readonly selectedAreas = new FormControl([]);

  /**
   * A {@link FormGroup} for use by the selector.
   */
  public readonly formGroup = new FormGroup({
    selectedAreas: this.selectedAreas
  });

  /**
   * Creates an instance of area selector component.
   *
   * @param route The {@link ActivatedRoute} instance to use.
   * @param router The {@link Router} instance to use.
   * @param routeMonitorService The {@link RouteMonitorService} instance to use.
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly routeMonitorService: RouteMonitorService
  ) {
    this.setupSelectedAreasUpdater();
    this.setupManagedParameterUpdater();
    this.setupFormGroupDisabledUpdater();
    this.setupSlavingModeQueryParameterUpdater();
    this.setupSlaveQueryParamterUpdater();
  }

  /**
   * Executes when the component is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Handle the change of the selected areas.
   *
   * @param event The selected areas or the native change event.
   */
  public onChange(event: ApiLocalAuthorityGroup[]): void {
    if (event instanceof Array) {
      this.selectedAreaChangedTrigger$.next(event.map(x => x.id));
    }
  }

  /**
   * Deal with button presses.
   *
   * @param button The pressed button.
   */
  public onButton(button: ButtonDefinition): void {
    switch (button.data) {
      case 'ToggleSlaving':
        this.slavingModeToggleTrigger$.next();
    }
  }

  /**
   * Set up a subscription to update the selected areas form control
   *              when the routing parameter changes.
   */
  private setupSelectedAreasUpdater(): void {
    this.parameterValues$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe({
      next: selectedAreas => this.selectedAreas.setValue(selectedAreas)
    });
  }

  /**
   * Set up a subscription to update the selected areas query parameter
   *              as the selected areas change.
   */
  private setupManagedParameterUpdater(): void {
    combineLatest([
      this.selectedAreaChangedTrigger$,
      this.parameterName$
    ]).pipe(
      filter(([_, parameter]) => parameter !== null && parameter !== undefined && parameter !== ''),
      takeUntil(this.destroyed$)
    ).subscribe({
      next: ([values, parameter]) => this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { [parameter]: values },
        queryParamsHandling: 'merge'
      })
    });
  }

  /**
   * Set up a subscription to disable or enable the selector based
   *              on whether we are currently in slave mode and whether or not
   *              we are the slave.
   */
  private setupFormGroupDisabledUpdater(): void {
    combineLatest([
      this.isSlaving$,
      this.slaveParameterName$
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe({
      next: ([isSlaving, slaveParameter]) => {
        if (isSlaving && !slaveParameter) {
          this.formGroup.disable();
        } else {
          this.formGroup.enable();
        }
      }
    });
  }

  /**
   * Set up a subscription to update the values of the slaving
   *              query parameter as the slaving mode changes.
   */
  private setupSlavingModeQueryParameterUpdater(): void {
    this.slavingModeToggleTrigger$.pipe(
      withLatestFrom(this.isSlaving$),
      map(([_, isSlaving]) => !isSlaving),
      takeUntil(this.destroyed$)
    ).subscribe({
      next: isSlaving => this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { isSlaving },
        queryParamsHandling: 'merge'
      })
    });
  }

  /**
   * Set up a subscription to update the slaved query parameter
   *              when in slave mode and we are the master.
   */
  private setupSlaveQueryParamterUpdater(): void {
    combineLatest([
      this.isSlaving$,
      this.slaveParameterName$,
      this.parameterValues$
    ]).pipe(
      filter(([isSlaving, parameter, _]) => isSlaving && parameter !== undefined && parameter !== null && parameter !== '')
    ).subscribe({
      next: ([_, parameter, values]) => this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { [parameter]: values },
        queryParamsHandling: 'merge'
      })
    });
  }
}
