import { GridReference } from 'src/app/local-authority';
import { SpatialLocation } from 'src/app/shared';

/**
 * Base interface for all spatially located objects.
 */
export interface LocatedObject {
  /**
   * Object spatial location.
   */
  location: GridReference;
}

/**
 * Represents a spatially located object together with its distance from a specific {@link SpatialLocation}.
 *
 * @template T The type of spatially located object.
 */
export class DistanceObject<T extends LocatedObject> {
  /**
   * Object distance from the base location.
   */
  public readonly distance: number;

  /**
   * The base location.
   */
  public readonly sourceLocation: GridReference;

  /**
   * The object.
   */
  public readonly object: T;

  /**
   * Creates an instance of distance object and calculates int distance from the base location.
   *
   * @param sourceLocation The base location.
   * @param object The located object.
   */
  constructor(sourceLocation: GridReference, object: T) {
    this.sourceLocation = sourceLocation;
    this.object = object;
    if (sourceLocation && object && object.location) {
      this.distance = sourceLocation.easting !== 0 && sourceLocation.northing !== 0 &&
        location && object.location.easting !== 0 && object.location.northing !== 0 ?
        Math.hypot(sourceLocation.easting - object.location.easting, sourceLocation.northing - object.location.northing)
        : 0;
    } else {
      this.distance = 0;
    }
  }
}
