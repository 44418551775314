import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that converts metres to miles.
 */
@Pipe({
  name: 'metresToMiles'
})
export class MetresToMilesPipe implements PipeTransform {

  /**
   * Transforms metres to miles.
   *
   * @param value The value in metres.
   * @returns The value in miles.
   */
  public transform(value: number): number {
    return value * 0.000621371;
  }

}
