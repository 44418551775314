<div class="modal-wrapper">
  <div *ngIf="title" class="modal-header">
    <div class="modal-title w-100">
      <h5>
        <span>{{title}}</span>
        <button type="button"
                class="btn-close me-0 float-end"
                aria-label="Close"
                (click)="dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </h5>
    </div>
  </div>
  <div *ngIf="notification" class="modal-body">
    <div class="modal-section form-section-component d-flex flex-fill flex-column">
      <span>{{notification}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit"
            (click)="dismiss()"
            class="btn btn-outline-dark">
      Ok
    </button>
  </div>
</div>
