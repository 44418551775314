<div class="input-group" [formGroup]="dateGroup">
  <button class="btn input-group-text" (click)="datePicker.toggle()"><span class="icon-calendar"></span></button>
  <input
    type="text"
    class="flex-fill border-1"
    formControlName="date"
    ngbDatepicker
    #datePicker="ngbDatepicker"
    (click)="datePicker.toggle()"
    (blur)="onTouched()"
    container="body">
</div>
