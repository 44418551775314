<fw-composite-input [prefixButtons]="areaPrefixButtons"
                    [suffixButtons]="areaSuffixButtons$ | async"
                    (buttonClicked)="onButton($event)">
  <ng-container *ngIf="areas$ | async as areas"
                [formGroup]="formGroup">
    <ng-select [items]="areas"
               [loading]="areas.length === 0"
               [multiple]="true"
               bindLabel="name"
               [closeOnSelect]="false"
               [searchable]="false"
               (change)="onChange($event)"
               bindValue="id"
               formControlName="selectedAreas"
               [placeholder]="(areas.length > 0 && 'Select areas...') || ''">

      <ng-template ng-header-tmp
                   let-items="areas">
        <button class="btn btn-link text-decoration-none selection-buttons"
                (click)="onChange(areas)">
          <span class="icon-generic-tick selection-icons"></span>Select All
        </button>
        <button class="btn btn-link text-decoration-none selection-buttons"
                (click)="onChange([])">
          <span class="icon-cancel-cross selection-icons"></span>Clear All
        </button>
      </ng-template>
      <ng-template ng-multi-label-tmp
                   let-items="items">
        <ng-container *ngIf="items.length > 1; then multiple; else single"></ng-container>
        <ng-template #multiple>
          <span>{{items.length}} selected...</span>
        </ng-template>
        <ng-template #single>
          <span>{{items[0].name}}</span>
        </ng-template>
      </ng-template>
      <ng-template ng-option-tmp
                   let-item="item"
                   let-item$="item$">
        <input type="checkbox"
               [checked]="item$.selected" />
        {{item.name}}
      </ng-template>
    </ng-select>
  </ng-container>
</fw-composite-input>
