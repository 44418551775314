import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Sanitzes the provided HTML.
 */
@Pipe({ name: 'trustedHtml' })
export class TrustedHtmlPipe implements PipeTransform {

  /**
   * Creates an instance of trusted html pipe.
   *
   * @param sanitizer The {@link DomSanitizer} instance to use.
   */
  constructor(private readonly sanitizer: DomSanitizer) { }

  /**
   * Sanitizes the provided HTML.
   *
   * @param value The HTML to sanitize.
   * @returns The sanitized HTML.
   */
  public transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
