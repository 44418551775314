import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LocalAuthorityService } from '../local-authority.service';
import { AuthorityDepartment, AuthorityDetails, Department } from '../model';


/**
 * A component to get all local authorities.
 */
@Component({
  selector: 'fw-authority-department-selector',
  templateUrl: './authority-department-selector.component.html',
  styleUrls: ['./authority-department-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthorityDepartmentSelectorComponent),
      multi: true
    }
  ]
})
export class AuthorityDepartmentSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {

  /**
   * A stream of the allowed authority types.
   */
  private authorityTypes$ = new BehaviorSubject<string[]>([]);

  /**
   * Gets the allowed authority types.
   */
  @Input() public get authorityTypes(): string[] {
    return this.authorityTypes$.value;
  }

  /**
   * Sets the allowed authority types.
   */
  public set authorityTypes(value: string[]) {
    this.authorityTypes$.next(value);
  }

  /**
   * Authority control of authority department selector component.
   */
  private authorityControl = new UntypedFormControl();

  /**
   * Department control of authority department selector component.
   */
  private departmentControl = new UntypedFormControl(null);

  /**
   * Authority changed of authority department selector component.
   */
  private authorityChanged = new Subject<AuthorityDetails>();

  /**
   * Authority changes of authority department selector component.
   */
  private readonly authorityChanges = this.authorityControl.valueChanges.pipe(
    map(x => x as AuthorityDetails),
    tap(x => {
      this.departmentControl.setValue(null);
      if (x) {
        this.departmentControl.enable();
      } else {
        this.departmentControl.disable();
      }
    })
  );

  /**
   * Creates the component and initialises its fields.
   *
   * @param localAuthorityService An instance of the {@link :LocalAuthorityService}.
   */
  constructor(private readonly localAuthorityService: LocalAuthorityService) { }

  /**
   * Input  of local authority list component.
   */
  public authorityGroup = new UntypedFormGroup({
    authority: this.authorityControl,
    department: this.departmentControl
  });

  /**
   * Authorities$  of local authority list component.
   */
  public readonly authorities$ = combineLatest([
    this.localAuthorityService.localAuthorities$.pipe(
      startWith([] as AuthorityDetails[])
    ),
    this.authorityTypes$
  ]).pipe(
    map(([authorities, authorityTypes]) => authorities.filter(
      authority => authorityTypes.includes(authority.type)
    )),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Destroyed$  of local authority list component.
   */
  private destroyed$ = new Subject<void>();

  /**
   * Departments$  of local authority list component.
   */
  public readonly departments$ = merge(this.authorityChanges, this.authorityChanged).pipe(
    switchMap(x => x && x.departments ? this.localAuthorityService.getAllDepartments(x.departments.href) : of([] as Department[])),
    map(departments => departments.filter(department => department.bookingRules$)),
    shareReplay({ refCount: false, bufferSize: 1 }),
  );

  /**
   * Called when the value is updated.
   *
   * @param value The new value.
   */
  public onChange = (value: AuthorityDepartment): void => { };

  /**
   * Determines whether touched on.
   */
  public onTouched = (): void => { };

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.authorityGroup.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe({
      next: (value: AuthorityDepartment) => this.onChange(value)
    });
    this.departments$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe();
  }


  /**
   * @inheritdoc
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * @inheritdoc
   */
  public writeValue(value: AuthorityDepartment): void {
    this.authorityGroup.setValue({
      authority: value.authority || null,
      department: value.department || null
    });
    this.authorityChanged.next(value.authority);
  }

  /**
   * @inheritdoc
   */
  public registerOnChange(onChange: (value: AuthorityDepartment) => void): void {
    this.onChange = onChange;
  }

  /**
   * @inheritdoc
   */
  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /**
   * @inheritdoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.authorityGroup.disable();
    } else {
      this.authorityGroup.enable();
    }
  }
}
