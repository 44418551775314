import { Injectable } from '@angular/core';
import moment from 'moment';

/**
 * A service to provide functionality to convert dates strings in objects to and from date objects.
 */
export interface IObjectDateParserService {
  /**
   * Convert all object fields that contain dates in iso8601 format to JavaScript Date objects.
   *
   * @param body The object to convert the dates in.
   */
  convertDates(body: any): void;

  /**
   * Convert all object fields that contain date objects to iso8601 format strings.
   *
   * @param body The object to convert the dates in.
   */
  convertIso8601(body: any): void;
}

/**
 * A service to provide functionality to convert dates strings in objects to and from date objects.
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectDateParserService implements IObjectDateParserService {
  private readonly iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  private readonly aspNetTimeSpanJsonRegex = /(-)?(\d*(?=.))?.?(\d+):(\d+):(\d+).?(\d{3})?/;

  /**
   * Convert all object fields that contain dates in iso8601 format to JavaScript Date objects.
   * Convert all object fields that contain .net TimeSpan moment duration objects.
   *
   * @param body The object to convert the dates in.
   */
  public convertDates(body: any): void {
    if (body !== null && body !== undefined && typeof body === 'object') {
      for (const key of Object.keys(body)) {
        const value = body[key];
        if (typeof value === 'object') {
          this.convertDates(value);
        } else if (this.isIso8601(value)) {
          body[key] = new Date(value);
        } else if (this.isTimeSpan(value)) {
          body[key] = moment.duration(value);
        }
      }
    }
  }

  /**
   * Convert all object fields that contain date objects to iso8601 format strings.
   *
   * @param body The object to convert the dates in.
   */
  public convertIso8601(body: any): void {
    if (body !== null && body !== undefined && typeof body === 'object') {
      for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof Date) {
          body[key] = value.toJSON();
        } else if (typeof value === 'object') {
          this.convertIso8601(value);
        }
      }
    }
  }

  /**
   * Checks if the value is a date in iso8601 format.
   *
   * @param value The value to check.
   * @returns A value indicating whether the passed value is an iso8601 date.
   */
  private isIso8601(value: any): boolean {
    return value != null ? this.iso8601.test(value) : false;
  }

  /**
   * Checks if the value is a serialised .net time span.
   *
   * @param value The value to check.
   * @returns A value indicating whether the passed value is a TimeSpan.
   */
  private isTimeSpan(value: any): boolean {
    return value != null ? this.aspNetTimeSpanJsonRegex.test(value) : false;
  }
}
