import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ChatterService } from './chatter.service';

/**
 * An HTTP interceptor that adds chatter hub session id to every API call.
 * The session id will be added via a custom header X-Chatter-Session.
 */
@Injectable()
export class ChatterSessionInterceptor implements HttpInterceptor {

  /**
   * Creates an instance of chatter session interceptor.
   *
   * @param chatterService The {@link ChatterService} instance to use.
   */
  constructor(private readonly chatterService: ChatterService) { }

  /**
   * @inheritdoc
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      if (this.chatterService.hubConnectionId && (new URL(req.url)).pathname.startsWith('/api')) {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'X-Chatter-Session': this.chatterService.hubConnectionId
          }
        });
      }
    // eslint-disable-next-line no-empty
    } catch { }
    return next.handle(req);
  }
}
