<ng-container [formGroup]="authorityGroup">
  <div class="input-fields">
    <label class="form-label">Authority</label>
    <ng-select *ngIf="authorities$ | async as authorities"
               appendTo="body"
               dropdownPosition="top"
               [items]="authorities"
               [loading]="authorities.length === 0"
               bindLabel="name"
               [closeOnSelect]="true"
               formControlName="authority"
               placeholder="Select authority..."
               aria-label="Select authority">
    </ng-select>
    <label class="form-label">Department</label>
    <ng-select appendTo="body"
               dropdownPosition="top"
               [items]="departments$ | async"
               bindLabel="name"
               [closeOnSelect]="true"
               formControlName="department"
               placeholder="Select department..."
               aria-label="Select department">
    </ng-select>
  </div>
</ng-container>
