import { Component, forwardRef, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Component that allows entering dates. Control value if a JS Date object.
 */
@Component({
  selector: 'fw-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateInputComponent,
      multi: true
    }
  ]
})
export class DateInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  /**
   * Set class 'composite-control' on the container.
   */
  @HostBinding('class.composite-control') public readonly baseClass = true;

  /**
   * An observable that fires when the component is destroyed.
   */
  private destroyed$ = new Subject<void>();

  /**
   * The form group that control uses internally.
   */
  public dateGroup = new UntypedFormGroup({
    date: new UntypedFormControl()
  });

  /**
   * Initialises a component.
   */
  public ngOnInit(): void {
    this.dateGroup.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(value => this.onChange(value.date));
  }

  /**
   * Performs component cleanup.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * The current method to notify consuming form about changes in control value.
   *
   * @param date The new date.
   */
  private onChange = (date: Date): void => { };

  /**
   * The current method to notify consuming form about touches.
   */
  public onTouched = (): void => { };

  /**
   * Sets the value of the control.
   *
   * @param value The value to set.
   */
  public writeValue(value: Date): void {
    this.dateGroup.setValue({ date: value });
  }

  /**
   * @inheritdoc
   */
  public registerOnChange(onChange: (date: Date) => void): void {
    this.onChange = onChange;
  }

  /**
   * @inheritdoc
   */
  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /**
   * @inheritdoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateGroup.disable();
    } else {
      this.dateGroup.enable();
    }
  }

  /**
   * Reflect validation error back to the container.
   *
   * @param control The control being validated.
   * @returns Validation errors or null if the control is valid.
   */
  public validate(control: UntypedFormControl): any {
    return this.dateGroup.valid ? null : { invalidDate: true };
  }
}
