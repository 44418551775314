import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { ButtonDefinition } from '../../toolbar/model/toolbar';

/**
 * A component that renders its content control and allow you to add buttons on the left or on the right of the control.
 */
@Component({
  selector: 'fw-composite-input',
  templateUrl: './composite-input.component.html',
  styleUrls: ['./composite-input.component.scss']
})
export class CompositeInputComponent {

  /**
   * Set class 'composite-control' on the container.
   */
  @HostBinding('class.composite-control') public readonly baseClass = true;

  /**
   * A collection of buttons to show before the field.
   */
  @Input() public prefixButtons: ButtonDefinition[] = [];

  /**
   * A collection of buttons to show after the field.
   */
  @Input() public suffixButtons: ButtonDefinition[] = [];

  /**
   * An output that emits when a button is clicked.
   */
  @Output() public readonly buttonClicked = new EventEmitter<ButtonDefinition>();
}
