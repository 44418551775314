import { Component, OnInit, forwardRef, OnDestroy, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl, Validator, NG_VALIDATORS } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Component that allows entering time. Control value is {@link NgbTimeStruct}.
 */
@Component({
  selector: 'fw-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TimeInputComponent,
      multi: true
    }
  ]
})
export class TimeInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  /**
   * Set class 'composite-control' on the container.
   */
  @HostBinding('class.composite-control') public readonly baseClass = true;

  /**
   * An observable that fires when the component is destroyed.
   */
  private destroyed$ = new Subject<void>();

  /**
   * The form group that control uses internally.
   */
  public timeGroup = new UntypedFormGroup({
    time: new UntypedFormControl(),
  });

  /**
   * Initialises a component.
   */
  public ngOnInit(): void {
    this.timeGroup.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(value => {
      this.onChange(value.time);
    });
  }

  /**
   * Performs component clean up.
   */
  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * The current method to notify consuming form about changes in control value.
   *
   * @param date The new value.
   */
  private onChange = (date: NgbTimeStruct): void => { };

  /**
   * The current method to notify consuming form about touches.
   */
  public onTouched = (): void => { };

  /**
   * Sets the value of the control.
   *
   * @param value The value to set.
   */
  public writeValue(value: NgbTimeStruct): void {
    this.timeGroup.setValue({ time: value });
  }

  /**
   * @inheritdoc
   */
  public registerOnChange(onChange: (date: NgbTimeStruct) => void): void {
    this.onChange = onChange;
  }

  /**
   * @inheritdoc
   */
  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /**
   * @inheritdoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeGroup.disable();
    } else {
      this.timeGroup.enable();
    }
  }

  /**
   * Reflect validation error back to the container.
   *
   * @param control The control being validated.
   * @returns Validation errors or null if the control is valid.
   */
  public validate(control: UntypedFormControl): any {
    return this.timeGroup.valid ? null : { invalidTime: true };
  }
}
