import { Component, Input, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * A component that displays a notification pop up.
 */
@Component({
  selector: 'fw-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {

  /**
   * Creates an instance of a notification modal component.
   *
   * @param activeModal The {@link NgbActiveModal} instance to use.
   */
  constructor(public readonly activeModal: NgbActiveModal) { }

  /**
   * The title of the notification modal.
   */
  @Input() public title: string;

  /**
   * The notification to display on the modal.
   */
  @Input() public notification: string;

  /**
   * Dismisses the modal.
   */
  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
