import groupBy from 'lodash-es/groupBy';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { defaultIfEmpty, filter, map, shareReplay, switchMap } from 'rxjs/operators';

import {
  ApiAppointment,
  ApiAppointmentAction,
  ApiAppointmentEvent,
  ApiAppointmentSearchLink,
  ApiAuthority,
  ApiCurrentUser,
  ApiDepartment,
  ApiSearch,
  ApiSearchClerk,
  ApiSearchEvent,
  ApiSearchEventCategory,
  ApiUser,
  LeaveType
} from 'src/app/api-model';
import { Appointment, AppointmentAction, AppointmentEvent, AppointmentSearchLink } from 'src/app/appointment';
import { CurrentUser } from 'src/app/currentUser';
import { DataStoreCollection, DataStoreObject, DataStoreService } from 'src/app/data-store';
import { AuthorityDetails, BookingRules, Department } from 'src/app/local-authority';
import { SearchEvent } from 'src/app/schedule';
import { Search, SearchEventCategory } from 'src/app/search';
import { ApiLeavePeriod, LeavePresentation, SearchClerk } from 'src/app/search-clerk';
import { ApiWorkingPattern } from 'src/app/search-clerk/model';
import { ObjectCollection } from 'src/app/shared';
import { User } from 'src/app/user';

/**
 * A class of functions to convert API provided data.
 */
export class ApiConverter {
  /**
   * For a given store collection generates an observable that emits a collection of all objects in the source collection.
   * The returned observable will emit every time the source collection or any of the collection objects change.
   *
   * @template T The type of objects contained in the collection.
   * @param storeCollection The source collection.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits a collection of objects contained in the source colelction.
   */
  public static loadStoreCollectionData<T>(
    storeCollection: DataStoreCollection<T>,
    dataStoreService: DataStoreService
  ): Observable<T[]> {
    return dataStoreService.loadCollection<T>(storeCollection.href).pipe(
      switchMap(collection => ApiConverter.loadStoreObjectsData(collection.data, dataStoreService))
    );
  }

  /**
   * For a given array of store objects generates an observable that emits a collection of all objects in the source array.
   * The returned observable will emit every time any of the objects change.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The source object array.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits a collection of objects contained in the source array.
   */
  public static loadStoreObjectsData<T>(storeObjects: DataStoreObject<T>[], dataStoreService: DataStoreService): Observable<T[]> {
    return combineLatest(ApiConverter.loadStoreObjects(storeObjects, dataStoreService)).pipe(
      defaultIfEmpty([] as T[]),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Creates a collection of observables emitting object data from a collection of store objects.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The objects to load.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A collection of observables that will load source objects.
   */
  public static loadStoreObjects<T>(storeObjects: DataStoreObject<T>[], dataStoreService: DataStoreService): Observable<T>[] {
    return storeObjects.map(search => ApiConverter.loadStoreObject(search, dataStoreService));
  }

  /**
   * Creates an observable that loads store object.
   *
   * @template T The type of object to load.
   * @param storeObject The store object to load.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable emitting object data when it is loaded or is changed.
   */
  public static loadStoreObject<T>(storeObject: DataStoreObject<T>, dataStoreService: DataStoreService): Observable<T> {
    return dataStoreService.loadObject<T>(storeObject.href).pipe(
      map(object => object.data)
    );
  }

  /**
   * Loads authority from the Api and converts to a business object.
   *
   * @param authority The authority store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the authority business object when it is loaded or is changed.
   */
  public static loadAuthority(authority: DataStoreObject<ApiAuthority>, dataStoreService: DataStoreService): Observable<AuthorityDetails> {
    return ApiConverter.loadStoreObject(authority, dataStoreService).pipe(
      map(apiAuthority => ApiConverter.convertApiAuthority(apiAuthority, dataStoreService))
    );
  }

  /**
   * Loads department from the Api and converts to a business object.
   *
   * @param department The department store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the department business object when it is loaded or is changed.
   */
  public static loadDepartment(
    department: DataStoreObject<ApiDepartment>, dataStoreService: DataStoreService
  ): Observable<Department> {
    return ApiConverter.loadStoreObject(department, dataStoreService).pipe(
      map(apiDepartment => ApiConverter.convertApiDepartment(apiDepartment, dataStoreService))
    );
  }

  /**
   * Loads appointment action from the Api and converts to a business object.
   *
   * @param action The appointment action store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the appointment action business object when it is loaded or is changed.
   */
  public static loadAppointmentAction(
    action: DataStoreObject<ApiAppointmentAction>, dataStoreService: DataStoreService
  ): Observable<AppointmentAction> {
    return ApiConverter.loadStoreObject(action, dataStoreService).pipe(
      map(apiAction => ApiConverter.convertApiAppointmentAction(apiAction, dataStoreService))
    );
  }

  /**
   * Loads event from the Api and converts to a business object.
   *
   * @param event The event store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the event business object when it is loaded or is changed.
   */
  public static loadEvent<T>(event: DataStoreObject<ApiSearchEvent>, dataStoreService: DataStoreService): Observable<SearchEvent<T>> {
    return ApiConverter.loadStoreObject(event, dataStoreService).pipe(
      map(apiEvent => ApiConverter.convertApiEvent(apiEvent, dataStoreService))
    );
  }

  /**
   * Converts current user from API object to business object.
   *
   * @param apiCurrentUser The API object to convert.
   * @param dataStoreService The {@link DataStoreService} instance to use.
   * @returns The formatted business object.
   */
  public static convertApiCurrentUser(apiCurrentUser: ApiCurrentUser, dataStoreService: DataStoreService): CurrentUser {
    return apiCurrentUser ? {
      isManager: apiCurrentUser.groups.includes('managers'),
      name: apiCurrentUser.name
    } : null;
  }

  /**
   * Converts event from Api to business object.
   *
   * @param apiEvent An event api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An event business object.
   */
  public static convertApiEvent<T>(apiEvent: ApiSearchEvent, dataStoreService: DataStoreService): SearchEvent<T> {
    return {
      href: apiEvent.href,
      eventData: apiEvent.data as T,
      childEventsHref: apiEvent.childEvents.href
    };
  }

  /**
   * Converts search from Api to business object.
   *
   * @param apiSearch A search api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A search business object.
   */
  public static convertApiSearch(apiSearch: ApiSearch, dataStoreService: DataStoreService): Search {
    return {
      title: apiSearch.title,
      address: apiSearch.address,
      href: apiSearch.href,
      link: apiSearch.link,
      customerName: apiSearch.customerName,
      localAuthorityName: apiSearch.localAuthorityName,
      id: apiSearch.id,
      eventsHref: apiSearch.events.href,
      tags$: ApiConverter.loadStoreObject(apiSearch.tags, dataStoreService).pipe(
        map(tags => tags as string[]),
        shareReplay({ refCount: false, bufferSize: 1 })
      ),
      progress$: ApiConverter.loadStoreObject(apiSearch.progress, dataStoreService).pipe(
        map(progress => ({
          edd: progress.edd,
          oedd: progress.oedd,
          hasDataCollection: progress.hasDataCollection,
          hasAgentSearch: progress.hasAgentSearch,
          hasSiteVisit: progress.hasSiteVisit,
          hasPendingDataCollection: progress.hasPendingDataCollection,
          hasPendingAgentSearch: progress.hasPendingAgentSearch,
          hasPendingSiteVisit: progress.hasPendingSiteVisit,
          hasOpenQueries: progress.hasOpenQueries,
          isCancelled: progress.isCancelled,
          isComplete: progress.isComplete,
          currentGroup: progress.currentGroup,
          percentageComplete: progress.percentageComplete
        })),
        shareReplay({ refCount: false, bufferSize: 1 })
      ),
    };
  }

  /**
   * Converts search link from Api to business object.
   *
   * @param apiSearchLink A search link api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A search link business object.
   */
  public static convertApiSearchLink(apiSearchLink: ApiAppointmentSearchLink, dataStoreService: DataStoreService): AppointmentSearchLink {
    return {
      search: ApiConverter.loadSearch(apiSearchLink.search, dataStoreService),
      dataTypes: apiSearchLink.dataTypes,
      href: apiSearchLink.href,
    };
  }

  /**
   * Converts authority from Api to business object.
   *
   * @param apiAuthority An authority api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An authority business object.
   */
  public static convertApiAuthority(apiAuthority: ApiAuthority, dataStoreService: DataStoreService): AuthorityDetails {
    return {
      name: apiAuthority.name,
      location: apiAuthority.location,
      href: apiAuthority.href,
      departments: apiAuthority.departments,
      type: apiAuthority.typeName
    };
  }

  /**
   * Converts department from Api to business object.
   *
   * @param apiDepartment A department api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A department business object.
   */
  public static convertApiDepartment(apiDepartment: ApiDepartment, dataStoreService: DataStoreService): Department {
    return {
      name: apiDepartment.name,
      href: apiDepartment.href,
      location: apiDepartment.location,
      bookingRules$: apiDepartment.bookingRules ?
        ApiConverter.loadStoreObject(apiDepartment.bookingRules, dataStoreService)
        : null as Observable<BookingRules>
    };
  }

  /**
   * Converts appointment action from Api to business object.
   *
   * @param apiAppointmentAction An appointment action api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A appointment action business object.
   */
  public static convertApiAppointmentAction(
    apiAppointmentAction: ApiAppointmentAction,
    dataStoreService: DataStoreService
  ): AppointmentAction {
    return apiAppointmentAction ? {
      actionType: apiAppointmentAction.actionType,
      confirmationData: apiAppointmentAction.confirmationData,
      text: apiAppointmentAction.text,
      actionEventType: apiAppointmentAction.actionEventType,
      searchesToConfirm: apiAppointmentAction.searchesToConfirm,
      newAppointmentHref: apiAppointmentAction.newAppointmentHref
    } : null;
  }

  /**
   * Loads search clerk from the Api and converts to a business object.
   *
   * @param searchClerk The search clerk store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the search clerk business object when it is loaded or is changed.
   */
  public static loadSearchClerk(searchClerk: DataStoreObject<ApiSearchClerk>, dataStoreService: DataStoreService): Observable<SearchClerk> {
    return ApiConverter.loadStoreObject(searchClerk, dataStoreService).pipe(
      filter(apiSearchClerk => apiSearchClerk !== null && apiSearchClerk !== undefined),
      map(apiSearchClerk => this.convertSearchClerk(apiSearchClerk, dataStoreService)),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Loads search clerk from the Api using href and converts to a business object.
   *
   * @param searchClerkHref The URI of the search clerk.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the search clerk business object when it is loaded or is changed.
   */
  public static loadSearchClerkFromHref(searchClerkHref: string, dataStoreService: DataStoreService): Observable<SearchClerk> {
    return dataStoreService.loadObject<ApiSearchClerk>(searchClerkHref).pipe(
      map(apiSearchClerk => this.convertSearchClerk(apiSearchClerk.data, dataStoreService))
    );
  }

  /**
   * Converts search clerk from Api to business object.
   *
   * @param apiSearchClerk A search clerk api object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A search clerk business object.
   */
  public static convertSearchClerk(apiSearchClerk: ApiSearchClerk, dataStoreService: DataStoreService): SearchClerk {
    return {
      name: apiSearchClerk.name,
      location: apiSearchClerk.location,
      key: apiSearchClerk.href,
      getCalendar: (startDate: Date, days: number) => {
        const params = { startDate, days };
        return dataStoreService.loadCollection<ApiAppointment>(apiSearchClerk.calendarHref, { name: JSON.stringify(params), params }).pipe(
          switchMap(calendar => ApiConverter.loadStoreObjectsData(calendar.data, dataStoreService)),
          map(appointments => Array.from(Array(days).keys()).map((offset) => {
            const date = moment(startDate).add(offset, 'days').startOf('day');
            const dayAppointments = appointments.filter(a => moment(a.start).startOf('day').valueOf() === date.valueOf()
              || (date.valueOf() < moment(a.finish).valueOf()
                && date.valueOf() > moment(a.start).startOf('day').valueOf()))
              .map(a => ApiConverter.convertApiAppointment(a, dataStoreService));
            const leave$ = dataStoreService.loadObject<ApiLeavePeriod[]>(apiSearchClerk.leaveHref).pipe(
              map(b => groupBy(b.data, 'leaveType')),
              map(g => Object.entries(g).map(([key, group]) => this.convertToLeavePresentation(group, date, key as LeaveType)))
            );
            const isNonWorkingDay$ = dataStoreService.loadObject<ApiWorkingPattern>(apiSearchClerk.workingPatternHref).pipe(
              map(b => this.isSearchClerkWorking(b.data, date)));
            return {
              date: date.toDate(),
              appointments: dayAppointments,
              leave$,
              isNonWorkingDay$
            };
          }))
        );
      }
    };
  }

  /**
   * Calculates the correct values and Converts to leave presentation.
   *
   * @param leave The leave object to convert.
   * @param dateToCheck The date to compare against.
   * @param leaveType The type of leave.
   * @returns Leave presentation.
   */
  private static convertToLeavePresentation(leave: ApiLeavePeriod[], dateToCheck: moment.Moment, leaveType: LeaveType): LeavePresentation {

    const validLeaves = leave.filter(c => moment(c.start.date).startOf('day').valueOf() <= dateToCheck.valueOf() &&
      moment(c.end.date).startOf('day').valueOf() >= dateToCheck.valueOf());

    const morningLeave = validLeaves.some(c => moment(c.start.date).startOf('day').valueOf() < dateToCheck.valueOf() ||
      (moment(c.start.date).startOf('day').valueOf() === dateToCheck.valueOf() && c.start.session === 'Morning'));

    const afternoonLeave = validLeaves.some(c => moment(c.end.date).startOf('day').valueOf() > dateToCheck.valueOf() ||
      (moment(c.end.date).startOf('day').valueOf() === dateToCheck.valueOf() && c.end.session === 'Afternoon'));

    return {
      morningLeave,
      afternoonLeave,
      leaveType
    };
  }

  /**
   * Determines whether search clerk working or not from working pattern for the given date.
   *
   * @param workingPattern The woking Pattern.
   * @param dateToCheck The date to check against.
   * @returns True if search clerk working.
   */
  private static isSearchClerkWorking(workingPattern: ApiWorkingPattern, dateToCheck: moment.Moment): boolean {
    if (workingPattern == null) {
      return false;
    }

    const weekDays: (keyof ApiWorkingPattern)[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const dayNumber = dateToCheck.day();
    const weekDayName = weekDays[dayNumber];

    return (dayNumber === 0 || dayNumber === 6) ? false : !workingPattern[weekDayName];
  }

  /**
   * Loads searches from the store collection and converts to a business object.
   *
   * @param searchLinks The store collection of searches.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits a collection of search links (as observables) when it is loaded or is changed.
   */
  public static loadSearchLinks(
    searchLinks: DataStoreCollection<ApiAppointmentSearchLink>,
    dataStoreService: DataStoreService
  ): Observable<ObjectCollection<AppointmentSearchLink>> {
    return dataStoreService.loadCollection<ApiAppointmentSearchLink>(searchLinks.href).pipe(
      map(links => this.convertSearchLinks(links.data, dataStoreService))
    );
  }

  /**
   * Loads searches from the store collection and converts to a business object.
   *
   * @param searches The store collection of searches.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits a collection of searches (as observables) when it is loaded or is changed.
   */
  public static loadSearches(
    searches: DataStoreCollection<ApiSearch>,
    dataStoreService: DataStoreService
  ): Observable<ObjectCollection<Search>> {
    return dataStoreService.loadCollection<ApiSearch>(searches.href).pipe(
      map(searchCollection => this.convertSearches(searchCollection.data, dataStoreService))
    );
  }

  /**
   * Loads appointment events from the store collection and converts to a business object.
   *
   * @param events The store collection of appointment events.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits a collection of appointment events (as observables) when it is loaded or is changed.
   */
  public static loadAppointmentEvents(
    events: DataStoreCollection<ApiAppointmentEvent>,
    dataStoreService: DataStoreService
  ): Observable<ObjectCollection<AppointmentEvent>> {
    return dataStoreService.loadCollection<ApiAppointmentEvent>(events.href).pipe(
      map(eventCollection => this.convertAppointmentEvents(eventCollection.data, dataStoreService))
    );
  }

  /**
   * Converts an array of appointment event store objects to a collection of business object observables.
   *
   * @param apiAppointmentEvents An array of store objects.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A collection of appointment event business objects (as observables).
   */
  public static convertAppointmentEvents(
    apiAppointmentEvents: DataStoreObject<ApiAppointmentEvent>[],
    dataStoreService: DataStoreService
  ): ObjectCollection<AppointmentEvent> {
    return apiAppointmentEvents.map(event => this.loadAppointmentEvent(event, dataStoreService));
  }

  /**
   * Loads appointment event from the Api and converts to a business object.
   *
   * @param apiAppointmentEvent The appointment event store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the appointment event business object when it is loaded or is changed.
   */
  public static loadAppointmentEvent(
    apiAppointmentEvent: DataStoreObject<ApiAppointmentEvent>,
    dataStoreService: DataStoreService
  ): Observable<AppointmentEvent> {
    return ApiConverter.loadStoreObject(apiAppointmentEvent, dataStoreService).pipe(
      map(apiEvent => ApiConverter.convertApiAppointmentEvent(apiEvent, dataStoreService))
    );
  }

  /**
   * Converts api appointment event to business object.
   *
   * @param apiAppointmentEvent The api appointmentevent to convert.
   * @param dataStoreService The data store service.
   * @returns The converted appointment event.
   */
  public static convertApiAppointmentEvent(apiAppointmentEvent: ApiAppointmentEvent, dataStoreService: DataStoreService): AppointmentEvent {
    return {
      id: apiAppointmentEvent.id,
      title: apiAppointmentEvent.title,
      typeName: apiAppointmentEvent.typeName,
      data: apiAppointmentEvent.data,
      createdAt: apiAppointmentEvent.createdAt,
      user$: ApiConverter.loadStoreObject(apiAppointmentEvent.user, dataStoreService).pipe(
        map(user => ApiConverter.convertUser(user, dataStoreService))
      )
    };
  }

  /**
   * Converts api user to business object.
   *
   * @param apiUser The api user to convert.
   * @param dataStoreService The data store service.
   * @returns The converted user.
   */
  private static convertUser(apiUser: ApiUser, dataStoreService: DataStoreService): User {
    return {
      name: apiUser.name
    };
  }

  /**
   * Converts an array of search store objects to a collection of business object observables.
   *
   * @param apiSearches An array of store objects.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A collection of search business objects (as observables).
   */
  public static convertSearches(apiSearches: DataStoreObject<ApiSearch>[], dataStoreService: DataStoreService): ObjectCollection<Search> {
    return apiSearches.map(search => this.loadSearch(search, dataStoreService));
  }

  /**
   * Converts an array of search link store objects to a collection of business object observables.
   *
   * @param apiSearchLinks An array of store objects.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns A collection of search business objects (as observables).
   */
  public static convertSearchLinks(
    apiSearchLinks: DataStoreObject<ApiAppointmentSearchLink>[],
    dataStoreService: DataStoreService
  ): ObjectCollection<AppointmentSearchLink> {
    return apiSearchLinks.map(link => this.loadSearchLink(link, dataStoreService));
  }

  /**
   * Converts api event category to business object.
   *
   * @param apiEventCategory The ApiEventCategory to convert.
   * @returns The converted event category.
   */
  public static convertApiEventCategory(apiEventCategory: ApiSearchEventCategory): SearchEventCategory {
    return {
      id: apiEventCategory.id,
      name: apiEventCategory.name,
      description: apiEventCategory.description
    };
  }

  /**
   * Loads search from the Api and converts to a business object.
   *
   * @param search The search store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the search business object when it is loaded or is changed.
   */
  public static loadSearch(search: DataStoreObject<ApiSearch>, dataStoreService: DataStoreService): Observable<Search> {
    return ApiConverter.loadStoreObject(search, dataStoreService).pipe(
      map(apiSearch => ApiConverter.convertApiSearch(apiSearch, dataStoreService))
    );
  }

  /**
   * Loads search from the Api and converts to a business object.
   *
   * @param search The search link store object.
   * @param dataStoreService The {@link DataStoreService} to use.
   * @returns An observable that emits the search business object when it is loaded or is changed.
   */
  public static loadSearchLink(
    search: DataStoreObject<ApiAppointmentSearchLink>,
    dataStoreService: DataStoreService
  ): Observable<AppointmentSearchLink> {
    return ApiConverter.loadStoreObject(search, dataStoreService).pipe(
      filter(apiSearch => apiSearch != null),
      map(apiSearch => ApiConverter.convertApiSearchLink(apiSearch, dataStoreService))
    );
  }

  /**
   * Converts api appointment to business object.
   *
   * @param apiAppointment The api appointment to convert.
   * @param dataStoreService The data store service.
   * @returns The converted appointment.
   */
  public static convertApiAppointment(apiAppointment: ApiAppointment, dataStoreService: DataStoreService): Appointment {
    return apiAppointment ? {
      href: apiAppointment.href,
      searchesHref: apiAppointment.searchLinks.href,
      authority: apiAppointment.authority ? ApiConverter.loadAuthority(apiAppointment.authority, dataStoreService) : null,
      start: apiAppointment.start,
      finish: apiAppointment.finish,
      capacity: apiAppointment.capacity,
      department: apiAppointment.department ? ApiConverter.loadDepartment(apiAppointment.department, dataStoreService) : null,
      searchClerk: ApiConverter.loadSearchClerk(apiAppointment.searchClerk, dataStoreService),
      searches: ApiConverter.loadSearchLinks(apiAppointment.searchLinks, dataStoreService),
      authorityTypes: apiAppointment.authorityTypes,
      type: apiAppointment.type,
      title: apiAppointment.title,
      isCourierModel: apiAppointment.isCourierModel,
      appointmentEvents$: ApiConverter.loadAppointmentEvents(apiAppointment.events, dataStoreService),
      appointmentEventHref: apiAppointment.events.href,
      advanceAction: {
        action$: ApiConverter.loadAppointmentAction(apiAppointment.action, dataStoreService),
        actionHref: apiAppointment.action.href
      },
      cancelAction: {
        action$: ApiConverter.loadAppointmentAction(apiAppointment.cancelAction, dataStoreService),
        actionHref: apiAppointment.cancelAction.href
      },
      removeSearchesAction: {
        action$: ApiConverter.loadAppointmentAction(apiAppointment.removeSearchAction, dataStoreService),
        actionHref: apiAppointment.removeSearchAction.href
      },
      moveSearchesAction: {
        action$: ApiConverter.loadAppointmentAction(apiAppointment.moveSearchAction, dataStoreService),
        actionHref: apiAppointment.moveSearchAction.href
      },
      appointmentStatusHref: apiAppointment.appointmentStatusHref
    } : null;
  }


  /**
   * Groups elements of an array using specified key lambda.
   *
   * @template TElement The type of array elements.
   * @template TGroup The type of group key.
   * @param source The source array.
   * @param grouping The function returning group keys.
   * @returns An array of groups where each group contains a group key and an array of values.
   */
  public static groupBy<TElement, TGroup>(
    source: TElement[],
    grouping: (value: TElement) => TGroup
  ): { key: TGroup; values: TElement[] }[] {
    return source.reduce((groups, item) => {
      const groupKey = grouping(item);
      let group = groups.find(g => g.key === groupKey);
      if (!group) {
        group = { key: groupKey, values: [] };
        groups.push(group);
      }
      group.values.push(item);
      return groups;
    }, []);
  }
}
